import { useState, useEffect, createContext } from "react"

// Utils
import { getUUIDFromUrl } from "utils/browser"
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"

const Context = createContext({})

const SimulationProvider = ({ children }) => {
  const [currentSimulation, setCurrentSimulation] = useState({
    auth: {},
    currentFormStep: "",
    leadUUID: "",
    nextFormStep: "",
    previousFormStep: "",
  })

  const setSimulation = (data) => {
    setCurrentSimulation(data)
    saveOnSessionStorage({ name: "simulation", value: data })
  }

  useEffect(() => {
    const simulationFromSessionStorage =
      getFromSessionStorage({ name: "simulation" }) || null
    const uuid = getUUIDFromUrl()

    if (
      simulationFromSessionStorage &&
      Object.keys(simulationFromSessionStorage).length > 0 &&
      uuid
    ) {
      setCurrentSimulation(simulationFromSessionStorage)
    } else {
      setSimulation({
        ...currentSimulation,
        currentFormStep: "vehicle-deal-properties",
      })
    }
  }, [])

  return (
    <Context.Provider value={{ simulation: currentSimulation, setSimulation }}>
      {children}
    </Context.Provider>
  )
}

export { SimulationProvider, Context }
