import { navigate } from "gatsby"
import getQueryStringFromObject from "../getQueryStringFromObject"

export const resetFocus = () => {
  const isClient = typeof window !== "undefined"
  if (!isClient) return
  const firstLink = document.querySelector(".ds-header__logo > a")
  if (firstLink) firstLink.focus()
}

export const getGoogleIdFormatted = (cookie) => {
  if (!cookie) return ""
  const cookieSplitted = cookie.split(".")
  return `${cookieSplitted[2]}.${cookieSplitted[3]}`
}

export const getCookies = () => {
  return document.cookie.split("; ").reduce((prev, current) => {
    const [name, ...value] = current.split("=")
    prev[name] = value.join("=")
    return prev
  }, {})
}

export const getUrlParamAll = () => {
  const isClient = () => typeof window !== "undefined"
  if (isClient()) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const keys = Array.from(urlSearchParams.keys())
    const values = Array.from(urlSearchParams.values())

    const res = keys.reduce((acc, curr, index) => {
      acc[curr] = values[index]
      return acc
    }, {})

    return res
  }
  return {}
}

export const getUrlParam = (key) => {
  const locationSearch = window.location.search.substr(1)
  const params = {}

  locationSearch.split("&").reduce((param, currentValue) => {
    let [key, value] = currentValue.split("=")
    param[key] = value

    return param
  }, params)

  return params[key]
}

export const getUUIDFromUrl = () => {
  const params = getUrlParamAll()
  const keys = {
    lead: "leadUUID",
    simulation: "simulationUUID",
    financing: "financingUUID",
  }
  const getValue = (name) => {
    return { name: name, value: params[name] }
  }

  if (params[keys.lead]) return getValue(keys.lead)
  if (params[keys.simulation]) return getValue(keys.simulation)
  if (params[keys.financing]) return getValue(keys.financing)
}

export const navigateWithQueryString = (path, query) => {
  const params = getUrlParamAll()

  const paramsToNavigate =
    path === "/"
      ? Object.keys(params).reduce((acc, key) => {
          if (key !== "leadUUID") {
            acc[key] = params[key]
          }

          return acc
        }, {})
      : params

  const queryUpdated = getQueryStringFromObject({
    ...paramsToNavigate,
    ...query,
  })

  return navigate(`${path}${queryUpdated}`)
}
