// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-continue-index-js": () => import("./../../../src/pages/Continue/index.js" /* webpackChunkName: "component---src-pages-continue-index-js" */),
  "component---src-pages-denied-proposal-index-js": () => import("./../../../src/pages/DeniedProposal/index.js" /* webpackChunkName: "component---src-pages-denied-proposal-index-js" */),
  "component---src-pages-form-index-js": () => import("./../../../src/pages/Form/index.js" /* webpackChunkName: "component---src-pages-form-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-unavailable-simulation-index-js": () => import("./../../../src/pages/UnavailableSimulation/index.js" /* webpackChunkName: "component---src-pages-unavailable-simulation-index-js" */)
}

