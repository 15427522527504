import { segment } from "@mobi/libraries/analytics"

//Utils
import { getUUIDFromUrl } from "utils/browser"
import { currencyToFloat } from "@mobi/utils/formatters/currency"
import { getFromSessionStorage } from "utils/storage"

const tracking = {
  pageLoad: () => {
    segment.page("iCarros page", {})
  },

  buttonClickSegment: ({ buttonName, buttonText, location }) => {
    segment.track(`iCarros Button clicked`, {
      buttonName,
      buttonText,
      location,
      leadUUID: getUUIDFromUrl()?.value ? getUUIDFromUrl()?.value : "",
    })
  },

  leadStart: (payload) => {
    segment.track("iCarros Lead Start", {
      fullName: payload.fullName,
      customer_type: "cpf",
      leadUUID: payload.uuid,
    })
  },

  leadStartFechada: (payload) => {
    // TODO: incluir as seguintes infos: fullName, gearDescription, plateLastNumber, colorDescription
    segment.track("iCarros Lead Start Sim Fechada", {
      brand: payload.vehicleDealProperties.brandModelVersion,
      category: payload.vehicleDealProperties.isNewVehicle ? "Zero" : "Usado",
      price: payload.vehicleDealProperties.vehicleValue,
      productId: payload.vehicleDealProperties.dealId,
      km: payload.vehicleDealProperties.vehicleKm,
      leadUUID: payload.uuid,
    })
  },

  vehicleDealProperties: (payload) => {
    segment.track("iCarros Lead Step Completed: Vehicles Data", {
      initialPrice: payload.initialPrice,
      finalPrice: payload.finalPrice,
      initialYear: payload.initialYear,
      finalYear: payload.finalYear,
      brand: payload.brand,
      model: payload.model,
      city: payload.city,
      purchasePeriod: payload.purchasePeriod,
      leadUUID: getUUIDFromUrl().value,
    })
  },

  vehiclesAds: (payload) => {
    segment.track("iCarros Lead Step Completed: Vehicles Ads", {
      brand: payload.brand,
      category: payload.category,
      price: payload.price,
      productId: payload.productId,
      gearDescription: payload.gearDescription,
      km: payload.km,
      plateLastNumber: payload.plateLastNumber,
      colorDescription: payload.colorDescription,
      leadUUID: getUUIDFromUrl().value,
    })
  },

  adsListView: (payload) => {
    const { filters, deals, currentPage } = payload

    segment.track("iCarros Ads List View", {
      currentPage,
      filters: {
        brand: filters.brand,
        model: filters.model,
        initialYear: filters.initialYear,
        finalYear: filters.finalYear,
        minKm: filters.minKm,
        maxKm: filters.maxKm,
        finalPlate: filters.finalPlate,
        isNewVehicle: filters.isNewVehicle,
        city: filters.city,
        kmRange: filters.kmRange,
        initialPrice: filters.initialPrice,
        finalPrice: filters.finalPrice,
        sort: filters.sort,
      },
      ads: deals.map((deal, index) => ({
        brand: deal.trimDescription,
        isZeroKm: deal.isZeroKm,
        price: deal.price,
        productId: deal.dealID.toString(),
        position: index + 1,
      })),
    })
  },

  purchasePeriod: (payload) => {
    segment.track("iCarros Lead Step Completed Sim Fechada: Purchase Period", {
      purchasePeriod: payload.purchasePeriod,
      leadUUID: getUUIDFromUrl().value,
    })
  },

  verificationCodeConfirmation: (payload) => {
    const isClosedFlow =
      getFromSessionStorage({ name: "isClosedFlow" }) || false
    const eventPayload = {
      notifyMethod: payload.notifyMethod,
      leadUUID: getUUIDFromUrl().value,
    }

    if (isClosedFlow) {
      segment.track(
        "iCarros Lead Step Completed Sim Fechada: verification-code-confirmation",
        eventPayload
      )
    } else {
      segment.track(
        "iCarros Lead Step Completed: verification-code-confirmation",
        eventPayload
      )
    }
  },

  simulationFail: (payload) => {
    const isClosedFlow =
      getFromSessionStorage({ name: "isClosedFlow" }) || false

    const eventPayload = {
      leadUUID: getUUIDFromUrl()?.value,
      ads: (payload.deals || []).map((deal, index) => ({
        brand: deal.trimDescription,
        isZeroKm: deal.isZeroKm,
        price: deal.price,
        productId: deal.dealID.toString(),
        position: index + 1,
      })),
    }

    if (isClosedFlow) {
      segment.track("iCarros Lead Not Approved Sim Fechada", eventPayload)
    } else {
      segment.track("iCarros Lead Not Approved", eventPayload)
    }
  },

  simulationSent: () => {
    const isClosedFlow =
      getFromSessionStorage({ name: "isClosedFlow" }) || false

    const eventPayload = {
      leadUUID: getUUIDFromUrl()?.value,
    }

    if (isClosedFlow) {
      segment.track("iCarros Simulation Sent Sim Fechada", eventPayload)
    } else {
      segment.track("iCarros Simulation Sent", eventPayload)
    }
  },

  simulationSuccess: (payload) => {
    const isClosedFlow =
      getFromSessionStorage({ name: "isClosedFlow" }) || false

    const installment = (payload?.simulationInstallments || []).find(
      (item) => item.selected || item.suggested
    )

    if (!installment) {
      return
    }

    const eventPayload = {
      annualEffectiveInterest: installment.annualEffectiveInterest,
      annualInterest: installment.annualInterest,
      contractRegistrationFee: currencyToFloat(payload.contractRegistrationFee),
      defaultDownPayment: currencyToFloat(payload.defaultDownPayment),
      downPayment: currencyToFloat(payload.downPayment),
      highChanceOfApproval: installment.highChanceOfApproval,
      installmentAmount: installment.amount,
      insurance: payload.financeProtectionInsurance || false,
      insuranceValue: installment.financeInsuranceInstallmentValue,
      iofValue: installment.iofValue,
      isDefaultDownPaymentSuggested:
        currencyToFloat(payload.downPayment) ===
        currencyToFloat(payload.defaultDownPayment),
      leadUUID: getUUIDFromUrl().value,
      minAcceptableDownPayment: payload.simulation.minAcceptableDownPayment,
      minDownPaymentForHighChanceApproval:
        payload.simulation.highChanceOfApprovalDownPayment,
      monthlyEffectiveInterest: installment.monthlyEffectiveInterest,
      monthlyInterest: installment.monthlyInterest,
      numberOfInstallments: installment.installment,
      propertyValuationFee: currencyToFloat(payload.propertyValuationFee),
      registrationFee: currencyToFloat(payload.simulation.registrationFee),
      suggested: installment.suggested,
      totalAmountFinanced: currencyToFloat(payload.totalAmountFinanced),
      totalAmountFinancedWithoutTax: currencyToFloat(
        payload.totalAmountFinancedWithoutTax
      ),
      totalPayable: currencyToFloat(payload.totalPayable),
    }

    if (isClosedFlow) {
      segment.track("iCarros Lead Approved Sim Fechada", eventPayload)
    } else {
      segment.track("iCarros Lead Completed", eventPayload)
    }
  },

  simulationUpdate: (payload) => {
    const isClosedFlow =
      getFromSessionStorage({ name: "isClosedFlow" }) || false

    const installment = payload.simulationInstallments.find(
      (item) => item.selected || item.suggested
    )
    const eventPayload = {
      annualEffectiveInterest: installment.annualEffectiveInterest,
      annualInterest: installment.annualInterest,
      contractRegistrationFee: currencyToFloat(
        payload.simulation.contractRegistrationFee
      ),
      defaultDownPayment: currencyToFloat(
        payload.simulation.defaultDownPayment
      ),
      downPayment: currencyToFloat(payload.simulation.downPayment),
      highChanceOfApproval: installment.highChanceOfApproval,
      installmentAmount: installment.amount,
      insurance: payload.simulation.financeProtectionInsurance || false,
      insuranceValue: installment.financeInsuranceInstallmentValue,
      iofValue: installment.iofValue,
      isDefaultDownPaymentSuggested:
        currencyToFloat(payload.simulation.downPayment) ===
        currencyToFloat(payload.simulation.defaultDownPayment),
      leadUUID: getUUIDFromUrl().value,
      minAcceptableDownPayment: payload.simulation.minAcceptableDownPayment,
      minDownPaymentForHighChanceApproval:
        payload.simulation.highChanceOfApprovalDownPayment,
      monthlyEffectiveInterest: installment.monthlyEffectiveInterest,
      monthlyInterest: installment.monthlyInterest,
      numberOfInstallments: installment.installment,
      propertyValuationFee: currencyToFloat(
        payload.simulation.propertyValuationFee
      ),
      registrationFee: currencyToFloat(payload.simulation.registrationFee),
      suggested: installment.suggested,
      totalAmountFinanced: currencyToFloat(
        payload.simulation.totalAmountFinanced
      ),
      totalAmountFinancedWithoutTax: currencyToFloat(
        payload.simulation.totalAmountFinancedWithoutTax
      ),
      totalPayable: currencyToFloat(payload.simulation.totalPayable),
    }

    if (isClosedFlow) {
      segment.track(
        "iCarros Update of Simulation Conditions Sim Fechada",
        eventPayload
      )
    } else {
      segment.track("iCarros Update of Simulation Conditions", eventPayload)
    }
  },
}

export default tracking
