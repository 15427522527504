const getQueryStringFromObject = (params = {}) =>
  Object.keys(params)
    .map((key, index) => {
      if (!index) {
        return `?${key + "=" + params[key]}`
      } else {
        return key + "=" + params[key]
      }
    })
    .join("&")

export default getQueryStringFromObject
